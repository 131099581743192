<template>
  <DeviceSearch />
</template>

<script>
export default {
  name: "DeviceSearchView",
  components: {
    DeviceSearch: () => import('@/components/Device/DeviceSearch.vue')
  },
  metaInfo () {
    return {
      title: this.$t('deviceSearchComponent.searchDevices')
    }
  }
}
</script>
